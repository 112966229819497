<template>
  <div>
    <v-card elevation="6" class="pa-3">
      <v-subheader class="ma-0 pa-0">
        <h4 class="bold primary--text">Simulação de Valor Locatício com base na renda</h4>
      </v-subheader>
      <h6 class="text_dark_grey">Por favor, insira o valor da Renda Bruta dos Locatário(s), para obter a simulação do valor desejável do seu aluguel com os encargos.</h6>
      <br />
      <v-form v-model="valid">
        <v-row class="pa-0 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            <MoneyField
              v-model="renda_bruta"
              label="Valor da renda bruta"
            ></MoneyField>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            <MoneyField
              v-model="valor_locaticio"
              label="Valor locatício"
              messages="(valor do aluguel + encargos)"
              readonly
            ></MoneyField>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    renda_bruta: null
  }),
  computed: {
    valor_locaticio () {
      return this.renda_bruta / 3
    }
  }
}
</script>
